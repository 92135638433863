<template>
  <ProductCardCarousel
    v-if="layout === 'carousel'"
    :products="products"
    :tracking="{ provider, list }"
    v-on="addToCartListener ? { addToCart: handleAddToCart } : {}"
  />
  <ProductCardGrid
    v-if="layout === 'grid'"
    :block-position="blockPosition"
    :products="products"
    :tracking="{ provider, list }"
    v-on="addToCartListener ? { addToCart: handleAddToCart } : {}"
  />
</template>

<script lang="ts" setup>
import { getCurrentInstance } from 'vue'

import { useListTrackingData } from '../../composables/useProductTracking'
import type { Products } from '../../models/product'
import type { ProductCardProps } from '../ProductCard/ProductCard.vue'

import ProductCardCarousel from './ProductCardCarousel.vue'
import ProductCardGrid from './ProductCardGrid.vue'

const props = defineProps<{
  layout: 'grid' | 'carousel'
  products: Products
  provider: 'algolia'
  blockPosition?: number
}>()

const emit = defineEmits<{
  (e: 'add-to-cart', data: { status: string; product: ProductCardProps }): void
}>()

const addToCartListener = getCurrentInstance()?.vnode.props?.onAddToCart

const list = useListTrackingData({ blockPosition: props.blockPosition })
function handleAddToCart(product: ProductCardProps) {
  return ({ status }: { status: string }) => {
    emit('add-to-cart', { status, product })
  }
}
</script>
