<template>
  <div v-if="hasProducts" class="w-full">
    <BlockTitle :subtitle="subtitle" :title="title" />

    <ProductCardCollection
      :block-position="blockPosition"
      :layout="layout"
      :products="products || []"
      provider="algolia"
    />

    <div v-if="hasCtaLink" class="mt-8 flex justify-center">
      <CTALink :cta-link="ctaLink" width="fixed" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { RefinedProductsApiProps } from '@backmarket/http-api/src/api-specs-content/models/refined-products'
import ProductCardCollection from '@backmarket/nuxt-layer-recommendation/ProductCardCollection.vue'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import type { ContentBlockProps } from '../../models/content-block'
import { useSearchProducts } from '../../products/useSearchProducts'
import BlockTitle from '../../shared-components/BlockTitle/BlockTitle.vue'
import CTALink from '../../shared-components/CTALink/CTALink.vue'

export type RefinedProductsProps = RefinedProductsApiProps & ContentBlockProps

const props = withDefaults(defineProps<RefinedProductsProps>(), {
  ctaLink: undefined,
})

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])

const { data: products } = await useSearchProducts({
  productQuery: props.productQuery,
})
const layout = computed(() =>
  props.productLayout === 'grid' ? 'grid' : 'carousel',
)

const hasCtaLink = computed(() => !isEmpty(props.ctaLink))

const hasProducts = computed(() => products.value && products.value.length > 0)
</script>
